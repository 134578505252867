<template>
    <Toast position="top-center" group="tc"></Toast>
    <template v-if="isAccess">
        <div class="card">
            <Dialog header="店铺授权" v-model:visible="addstoredialog" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :modal="true">
                <Message severity="warn">请确认当前网络IP是授权店铺的IP！</Message>
                <div style="justify-content:center;display:flex;align-items:center;padding: 0.5rem;"><label>店铺名称:</label><InputText v-model="newStoreName" type="text" class="p-inputtext-lg ml-3"  placeholder="请输入店铺名称" /></div>
                <template #footer>    
                    <Button label="复制授权网址" @click="getAuth()"/>
                    <Button label="直接跳转授权" @click="getAuth(false)"/>
                </template>
            </Dialog>
            <div class="mb-3" style="justify-content:space-between;display: flex;border-radius: 4px">
                <Button icon="pi pi-plus-circle" label="授权店铺" @click="openaddstore" />
            </div>
            <DataTable stripedRows :value="storelist" responsiveLayout="scroll" scrollable scrollHeight="calc(100vh - 16.5rem)">
                <Column field="storeName" header="店铺名称"></Column>
                <Column field="accountAuth" header="店铺授权"></Column>
                <Column field="cpcAuth" header="广告授权"></Column>
                <Column header="操作">
                    <template #body>管理</template>
                </Column>
            </DataTable>
        </div>
    </template>
    <template v-else>
        <Access />
    </template>
</template>

<script>
import Access from '../components/Access.vue';
import Service from '../service/Service';
import useClipboard from 'vue-clipboard3'
    export default {
    data(){
        return{
            storelist:[],
            isAccess:false,
            systemservice:null,
            addstoredialog:false,
            user:null,
            newStoreName:null
        }
    },
    created() {
        this.service = new Service();
        this.user=JSON.parse(localStorage.getItem('user'));
    },
    mounted(){
        
            if(this.user.ManageStore=='1'){
                this.isAccess=true;
            }
            else{
                return;
            }
            this.getStores().then(stores=>{
                var sellerids=[];
                stores.forEach(store => {
                    this.storelist.push({sellerId:store.sellerId,storeName:store.storeName,accountAuth:'已授权',cpcAuth:'-'});
                    sellerids.push("'"+store.sellerId+"'");
                });
                const formData = new FormData();
                formData.append('sellerids', sellerids.join(','));
                var url='system/getmanagestorelist.php';
                this.service.sendHttp(url,'POST',formData).then(res=>res.json()).then(data => {
                    if(data.result=='success'){
                        data.stores.forEach(store=>{
                            this.storelist.forEach(s=>{
                                if(s.sellerId.trim()==store.SellerId.trim()){
                                    s.cpcAuth=store.cpc;
                                }
                            });
                        });
                    } 
                });
            });
            
        },
        components: {
		'Access': Access
	},
    methods:{
        getStores(){
                var url='system/getstorelist.php';
                const formData = new FormData();
                formData.append('userid', this.user.UserId);
                formData.append('owner', this.user.Owner);
                return this.service.sendHttp(url,'POST',formData).then(res=>res.json()).then(data=>{
                    if(data.result=='success'){
                        localStorage.setItem("stores",JSON.stringify(data.stores));
                        return data.stores;
                    }
                    return[];
                    
            });
        },
        getAuth(iscopy=true){
            if(this.newStoreName==null||this.newStoreName==''){
                this.showError('请先填写店铺名字！');
                return;
            }
            var owner=this.user.Owner;
            var json='{"storeName":"'+this.newStoreName+'","owner":"'+owner+'"}';
            var state=this.jsonToState(json);
            var url='https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.6b0c56b3-17a7-463c-948b-9bedc57687f3&redirect_uri=https://mizongkeji.xyz/SPApi/redirect&state='+state;
            if(iscopy){
                this.copy(url);
            }else{
                window.open(url);
            }
            
        },
        openaddstore(){
            this.addstoredialog=true;
        },
        jsonToState (str) {  //加密字符串
            //定义密钥，36个字母和数字
            var key = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var len = key.length;  //获取密钥的长度
            var a = key.split("");  //把密钥字符串转换为字符数组
            var s = "",b, b1, b2, b3;  //定义临时变量
            for (var i = 0; i <str.length; i ++) {  //遍历字符串
                b = str.charCodeAt(i);  //逐个提取每个字符，并获取Unicode编码值
                b1 = b % len;  //求Unicode编码值得余数
                b = (b - b1) / len;  //求最大倍数
                b2 = b % len;  //求最大倍数的于是
                b = (b - b2) / len;  //求最大倍数
                b3 = b % len;  //求最大倍数的余数
                s += a[b3] + a[b2] + a[b1];  //根据余数值映射到密钥中对应下标位置的字符
            }
            return s;  //返回这些映射的字符
        },
        copy(text){
            const { toClipboard } = useClipboard();
            toClipboard(text).then(
                ()=>{this.showSuccess('复制成功！')},
                ()=>{this.showError('复制失败！')}
            );
        },
        showSuccess(mes='核对成功!') {
            this.$toast.add({severity: 'success', summary: mes,  group: 'tc',life:1000});
        },
        showError(mes='核对失败!') {
            this.$toast.add({severity: 'error', summary: mes,  group: 'tc',life:1000});
        },
    }
    }
</script>